import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{attrs:{"flat":""}},[_c(VCardText,[(_vm.mostrarResultadosFinales)?_c(VChip,{staticClass:"mb-2",attrs:{"label":"","small":"","color":_vm.respuestaEsCorrecta ? 'success' : 'error'}},[_c(VIcon,{staticClass:"mr-2",attrs:{"small":""}},[_vm._v(_vm._s(_vm.respuestaEsCorrecta ? "mdi-check" : "mdi-close"))]),_c('span',[_vm._v(_vm._s(_vm.respuestaEsCorrecta ? "Reactivo acertado" : "Reactivo no acertado"))])],1):_vm._e(),_c('div',{staticClass:"ck-content",domProps:{"innerHTML":_vm._s(_vm.reactivo.texto)}}),(_vm.reactivo.tipo === 'abierta')?_c(VTextField,{attrs:{"filled":"","readonly":_vm.soloLectura,"label":"Respuesta"},on:{"change":function($event){_vm.respuesta && _vm.actualizarRespuesta()}},scopedSlots:_vm._u([{key:"append",fn:function(){return [(_vm.loading)?_c(VBtn,{attrs:{"icon":"","loading":"","small":""}}):(_vm.respuesta && !_vm.loading)?_c(VTooltip,{attrs:{"left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c(VBtn,_vm._g(_vm._b({attrs:{"icon":"","small":"","plain":""}},'v-btn',attrs,false),on),[_c(VIcon,[_vm._v("mdi-cloud-check-outline")])],1)]}}],null,false,1157577719)},[_c('span',[_vm._v("Respuesta guardada")])]):_vm._e()]},proxy:true}],null,false,145484879),model:{value:(_vm.respuesta),callback:function ($$v) {_vm.respuesta=$$v},expression:"respuesta"}}):(_vm.esReactivoTipo('opciones') && _vm.esSeleccion('unica'))?_c(VRadioGroup,{staticClass:"mt-0",on:{"change":function($event){return _vm.actualizarRespuesta()}},model:{value:(_vm.respuesta),callback:function ($$v) {_vm.respuesta=$$v},expression:"respuesta"}},_vm._l((_vm.reactivo.respuestas),function(respuesta){return _c(VRadio,{key:respuesta._id,attrs:{"label":respuesta.texto,"value":respuesta._id,"readonly":_vm.soloLectura,"disabled":_vm.loading},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.mostrarResultadosFinales)?_c('span',{staticClass:"px-2 py-1",class:{
                'success lighten-4': _vm.getRespuestaEsCorrecta(respuesta._id),
                'error lighten-4': !_vm.getRespuestaEsCorrecta(respuesta._id),
              },staticStyle:{"border-radius":"5px"}},[_c('span',[_vm._v(_vm._s(respuesta.texto))])]):_c('span',{staticClass:"px-2 py-1"},[_c('span',[_vm._v(_vm._s(respuesta.texto))])])]},proxy:true}],null,true)})}),1):(_vm.esReactivoTipo('opciones') && _vm.esSeleccion('multiple'))?_c('div',_vm._l((_vm.reactivo.respuestas),function(respuestaIteracion){return _c('div',{key:respuestaIteracion._id},[_c(VCheckbox,{staticClass:"ma-0",attrs:{"label":respuestaIteracion.texto,"value":respuestaIteracion._id,"readonly":_vm.soloLectura,"disabled":_vm.loading},on:{"change":function($event){return _vm.actualizarRespuesta()}},scopedSlots:_vm._u([{key:"label",fn:function(){return [(_vm.mostrarResultadosFinales)?_c('span',{staticClass:"px-2 py-1",class:{
                  'success lighten-4': _vm.getRespuestaEsCorrecta(
                    respuestaIteracion._id
                  ),
                  'error lighten-4': !_vm.getRespuestaEsCorrecta(
                    respuestaIteracion._id
                  ),
                },staticStyle:{"border-radius":"5px"}},[_c('span',[_vm._v(_vm._s(respuestaIteracion.texto))])]):_c('span',{staticClass:"px-2 py-1"},[_c('span',[_vm._v(_vm._s(respuestaIteracion.texto))])])]},proxy:true}],null,true),model:{value:(_vm.respuesta),callback:function ($$v) {_vm.respuesta=$$v},expression:"respuesta"}})],1)}),0):_vm._e(),(_vm.reactivo.tipo === 'abierta' && _vm.mostrarResultadosFinales)?_c('div',{staticClass:"success lighten-4 pa-1 mb-2"},[_c('h5',{staticClass:"px-2"},[_vm._v("Respuestas correctas")]),_c('ul',_vm._l((_vm.reactivo.respuestas),function(respuesta){return _c('li',{key:respuesta._id},[_vm._v(" "+_vm._s(respuesta.texto)+" ")])}),0)]):_vm._e(),(_vm.mostrarResultadosFinales)?_c(VRow,{attrs:{"dense":""}},[_c(VCol,{attrs:{"cols":"12"}},[_c(VBtn,{attrs:{"x-small":"","color":"primary","text":""},on:{"click":function($event){_vm.showSolucion = !_vm.showSolucion}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"x-small":""}},[_vm._v(_vm._s(_vm.showSolucion ? "mdi-chevron-down" : "mdi-chevron-right"))]),_c('span',[_vm._v("Mostrar solución")])],1)],1),(_vm.showSolucion)?_c(VCol,{attrs:{"cols":"12"}},[(_vm.reactivo.solucion)?_c('div',{staticClass:"ck-content",domProps:{"innerHTML":_vm._s(_vm.reactivo.solucion)}}):_c('span',[_vm._v("Sin solución para mostrar")])]):_vm._e()],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }