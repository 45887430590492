<template>
  <div>
    <v-card flat>
      <v-card-text>
        <v-chip
          v-if="mostrarResultadosFinales"
          label
          small
          :color="respuestaEsCorrecta ? 'success' : 'error'"
          class="mb-2"
        >
          <v-icon small class="mr-2">{{
            respuestaEsCorrecta ? "mdi-check" : "mdi-close"
          }}</v-icon>
          <span>{{
            respuestaEsCorrecta ? "Reactivo acertado" : "Reactivo no acertado"
          }}</span>
        </v-chip>

        <div v-html="reactivo.texto" class="ck-content"></div>

        <v-text-field
          v-if="reactivo.tipo === 'abierta'"
          v-model="respuesta"
          filled
          :readonly="soloLectura"
          label="Respuesta"
          @change="respuesta && actualizarRespuesta()"
        >
          <template #append>
            <v-btn v-if="loading" icon loading small></v-btn>
            <v-tooltip v-else-if="respuesta && !loading" left>
              <template #activator="{ on, attrs }">
                <v-btn v-on="on" v-bind="attrs" icon small plain>
                  <v-icon>mdi-cloud-check-outline</v-icon>
                </v-btn>
              </template>
              <span>Respuesta guardada</span>
            </v-tooltip>
          </template>
        </v-text-field>

        <v-radio-group
          v-else-if="esReactivoTipo('opciones') && esSeleccion('unica')"
          v-model="respuesta"
          @change="actualizarRespuesta()"
          class="mt-0"
        >
          <v-radio
            v-for="respuesta in reactivo.respuestas"
            :key="respuesta._id"
            :label="respuesta.texto"
            :value="respuesta._id"
            :readonly="soloLectura"
            :disabled="loading"
          >
            <template #label>
              <span
                v-if="mostrarResultadosFinales"
                class="px-2 py-1"
                style="border-radius: 5px"
                :class="{
                  'success lighten-4': getRespuestaEsCorrecta(respuesta._id),
                  'error lighten-4': !getRespuestaEsCorrecta(respuesta._id),
                }"
              >
                <span>{{ respuesta.texto }}</span>
              </span>
              <span v-else class="px-2 py-1">
                <span>{{ respuesta.texto }}</span>
              </span>
            </template>
          </v-radio>
        </v-radio-group>

        <div v-else-if="esReactivoTipo('opciones') && esSeleccion('multiple')">
          <div
            v-for="respuestaIteracion in reactivo.respuestas"
            :key="respuestaIteracion._id"
          >
            <v-checkbox
              v-model="respuesta"
              :label="respuestaIteracion.texto"
              :value="respuestaIteracion._id"
              :readonly="soloLectura"
              :disabled="loading"
              class="ma-0"
              @change="actualizarRespuesta()"
            >
              <template #label>
                <span
                  v-if="mostrarResultadosFinales"
                  class="px-2 py-1"
                  style="border-radius: 5px"
                  :class="{
                    'success lighten-4': getRespuestaEsCorrecta(
                      respuestaIteracion._id
                    ),
                    'error lighten-4': !getRespuestaEsCorrecta(
                      respuestaIteracion._id
                    ),
                  }"
                >
                  <span>{{ respuestaIteracion.texto }}</span>
                </span>
                <span v-else class="px-2 py-1">
                  <span>{{ respuestaIteracion.texto }}</span>
                </span>
              </template>
            </v-checkbox>
          </div>
        </div>

        <div
          v-if="reactivo.tipo === 'abierta' && mostrarResultadosFinales"
          class="success lighten-4 pa-1 mb-2"
        >
          <h5 class="px-2">Respuestas correctas</h5>
          <ul>
            <li v-for="respuesta in reactivo.respuestas" :key="respuesta._id">
              {{ respuesta.texto }}
            </li>
          </ul>
        </div>

        <v-row v-if="mostrarResultadosFinales" dense>
          <v-col cols="12">
            <v-btn
              x-small
              color="primary"
              text
              @click="showSolucion = !showSolucion"
            >
              <v-icon x-small class="mr-2">{{
                showSolucion ? "mdi-chevron-down" : "mdi-chevron-right"
              }}</v-icon>
              <span>Mostrar solución</span>
            </v-btn>
          </v-col>
          <v-col v-if="showSolucion" cols="12">
            <div
              v-if="reactivo.solucion"
              v-html="reactivo.solucion"
              class="ck-content"
            ></div>
            <span v-else>Sin solución para mostrar</span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import {
  registrarRespuesta,
  getRespuesta,
} from "./reactivoAplicacionExamen.service";

export default {
  props: {
    reactivo: { type: Object, required: true },
    soloLectura: { type: Boolean, default: true },
    aplicacionFinalizada: { type: Boolean, default: false },
    esAplicacionAlumno: { type: Boolean, default: false },
    busGuardado: { required: true },
  },

  data: () => ({
    loading: false,
    respuesta: null,
    respuestaEsCorrecta: false,
    showSolucion: false,
  }),

  computed: {
    ...mapGetters(["sesionCursoProfesor"]),

    esReactivoTipo() {
      return function (tipoDeseado) {
        return this.reactivo && this.reactivo.tipo === tipoDeseado;
      };
    },

    esSeleccion() {
      return function (seleccionDeseada) {
        return this.reactivo && this.reactivo.seleccion === seleccionDeseada;
      };
    },

    esPrevisualizacion() {
      return !!this.$route.params.tipoCurso;
    },

    mostrarResultadosFinales() {
      if (!this.esPrevisualizacion)
        return (
          (this.esAplicacionAlumno && this.aplicacionFinalizada) ||
          !this.esAplicacionAlumno ||
          this.sesionCursoProfesor
        );
      else return false;
    },
  },

  mounted() {
    !this.$route.params.tipoCurso && this.cargarRespuesta();
    this.busGuardado.$on("guardarDatos", this.actualizarRespuesta);
  },

  methods: {
    async cargarRespuesta() {
      this.loading = true;

      try {
        const params = this.$route.params;

        const serverResponse = await getRespuesta({
          idExamen: params.idExamen,
          idResultado: params.idResultado,
          idReactivo: this.reactivo._id,
        });
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else if (serverResponse.respuesta) {
          this.setRespuestaLocal(serverResponse.respuesta);
          this.respuestaEsCorrecta = serverResponse.respuesta.correcta;
        } else this.setRespuestasDefault();
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    setRespuestaLocal(respuesta) {
      if (this.esReactivoTipo("abierta"))
        this.respuesta = respuesta.respuestaUnica;
      if (this.esReactivoTipo("opciones") && this.esSeleccion("unica"))
        this.respuesta = respuesta.respuestaUnica[0];
      if (this.esReactivoTipo("opciones") && this.esSeleccion("multiple"))
        this.respuesta = respuesta.respuestaUnica;
    },

    setRespuestasDefault() {
      if (this.esReactivoTipo("abierta")) this.respuesta = "";
      if (this.esReactivoTipo("opciones") && this.esSeleccion("multiple"))
        this.respuesta = [];
      if (this.esReactivoTipo("opciones") && this.esSeleccion("unica"))
        this.respuesta = null;
    },

    async actualizarRespuesta() {
      this.loading = true;

      try {
        const params = this.$route.params;
        const body = {
          respuesta: this.respuesta,
        };

        const serverResponse = await registrarRespuesta({
          body,
          idExamen: params.idExamen,
          idResultado: params.idResultado,
          idReactivo: this.reactivo._id,
        });
        this.loading = false;

        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    getRespuestaEsCorrecta(idRespuesta) {
      if (this.esReactivoTipo("opciones"))
        return !!this.reactivo.respuestas.find(
          (e) => e._id === idRespuesta && e.correcta
        );
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../classes/ck-content.css";
</style>
