<template>
  <div>
    <v-card flat>
      <v-skeleton-loader type="card-heading"> </v-skeleton-loader>
      <v-card-text>
        <v-skeleton-loader type="text"></v-skeleton-loader>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {};
</script>
