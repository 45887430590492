<template>
  <div>
    <v-tabs v-model="tab" vertical>
      <div>
        <v-list-item
          v-for="pagina in paginas"
          :key="pagina._id"
          :class="{ 'pagina-activa': tab == pagina._id }"
        >
          <v-list-item-content>
            <v-list-item-title>
              <a class="text-decoration-none" @click="tab = pagina._id">
                {{ pagina.nombre }}
              </a>
            </v-list-item-title>
            <v-list-item-subtitle>
              <span>{{ pagina.tipo }}</span>
            </v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>
      </div>
      <v-tabs-items v-model="tab" class="pl-2">
        <v-tab-item
          v-for="pagina in paginas"
          :key="pagina._id"
          :value="pagina._id"
        >
          <pagina-examen
            :pagina="pagina"
            :idExamen="idExamen"
            :soloLectura="soloLectura"
            :aplicacionFinalizada="aplicacionFinalizada"
            :busGuardado="busGuardado"
            :esAplicacionAlumno="esAplicacionAlumno"
            @siguientePagina="siguientePagina"
          ></pagina-examen>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
  </div>
</template>

<script>
import paginaAplicacionExamen from "./paginaAplicacionExamen.vue";

export default {
  components: {
    "pagina-examen": paginaAplicacionExamen,
  },

  props: {
    paginas: { type: Array, default: () => [] },
    idExamen: { type: String, required: false },
    soloLectura: { type: Boolean, default: true },
    aplicacionFinalizada: { type: Boolean, default: false },
    esAplicacionAlumno: { type: Boolean, default: false },
    busGuardado: { required: true },
  },

  data: () => ({
    tab: 0,
  }),

  mounted() {
    if (this.paginas.length) this.tab = this.paginas[0]._id;
  },

  methods: {
    siguientePagina() {
      const actual = this.paginas.findIndex((e) => e._id === this.tab);
      if (actual < 0) return;

      const siguiente = this.paginas[actual + 1];
      if (!siguiente) this.$emit("siguienteSeccion");
      else this.tab = siguiente._id;
    },
  },
};
</script>

<style lang="scss" scoped>
.pagina-activa {
  background-color: #eceff1;
}
</style>
