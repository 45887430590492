import {
  getServerRequest,
  putServerRequest,
  patchServerRequest,
  deleteServerRequest,
} from "@/components/globals/services/serverRequest.service";
import { store } from "@/store/store";

const httpURL = store.getters.httpURL;

export const getRespuesta = async ({
  idExamen,
  idReactivo,
  idResultado,
}) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenResultado/${idResultado}/examen/${idExamen}/reactivo/${idReactivo}/respuesta`;

  return await getServerRequest(url, config);
};

export const registrarRespuesta = async ({
  body,
  idExamen,
  idReactivo,
  idResultado,
}) => {
  const token = store.getters.sessionToken;
  const config = { headers: { token } };
  const url = `${httpURL}/examenResultado/${idResultado}/examen/${idExamen}/reactivo/${idReactivo}/respuesta`;

  return await putServerRequest(url, body, config);
};
