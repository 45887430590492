<template>
  <div>
    <v-container :class="{ 'pt-5': !esCampus }">
      <v-skeleton-loader
        v-if="!escuelaTienePermiso"
        type="list-item-two-line@5"
      ></v-skeleton-loader>
      <v-card v-else rounded="lg">
        <v-toolbar dense color="secondary" dark flat class="text-h6">
          <v-btn icon @click="regresarAction({ aplicacionCurso })">
            <v-icon>mdi-arrow-left</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <span>{{
            seccion ? seccion.examen.nombre : "Cargando sección..."
          }}</span>
          <v-spacer></v-spacer>
        </v-toolbar>

        <v-container v-if="loading || !seccion">
          <loading-seccion></loading-seccion>
        </v-container>

        <v-container v-else-if="seccion">
          <v-alert v-if="idCurso" type="info" text dense
            >Esta es solo una previsualización del examen. Así se verá al
            momento de contestarlo.</v-alert
          >
          <v-card-title class="pt-0 pb-2">
            <span>{{ seccion.nombre }}</span>
          </v-card-title>
          <v-divider class="m-3"></v-divider>
          <v-card-text>
            <v-chip label
              ><span
                ><strong>Tiempo de duración:</strong>
                {{ seccion.tiempo }} minutos</span
              ></v-chip
            >
            <div
              v-if="tieneDescripcion"
              v-html="seccion.descripcion"
              class="ck-content mt-2"
            ></div>

            <paginas-examen
              v-if="seccion.paginas.length"
              :paginas="seccion.paginas"
              :idExamen="seccion.examen._id"
              :soloLectura="soloLectura"
              :aplicacionFinalizada="esAplicacionFinalizada"
              :busGuardado="busGuardado"
              :esAplicacionAlumno="esAplicacionAlumno"
              @siguienteSeccion="siguienteSeccion()"
              class="pt-3"
            ></paginas-examen>
            <div v-else class="pt-3">
              <v-btn color="primary" small @click="siguienteSeccion()">
                <span>Continuar</span>
                <v-icon small class="ml-2">mdi-chevron-right</v-icon>
              </v-btn>
            </div>
          </v-card-text>
        </v-container>
      </v-card>
    </v-container>

    <cronometro-seccion
      v-if="cronometroActivo"
      :activo="cronometroActivo"
      :tiempoLimite="tiempoLimite"
      @tiempoAgotado="
        cronometroActivo = false;
        mostrarAgotadoDialog = true;
      "
      @minutoCumplido="minutoCumplido"
    ></cronometro-seccion>

    <tiempo-agotado
      v-if="mostrarAgotadoDialog"
      :mostrar="mostrarAgotadoDialog"
      @siguienteSeccion="siguienteSeccion()"
    ></tiempo-agotado>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import {
  cargarSeccionResultado,
  avanzarSeccionResultado,
  restarTiempoResultado,
} from "../examenAplicacionResultado.service";
import { cargarSeccionHistoricoExamen } from "../../../../historicoExamen/historicoExamen.service";

import loadingSeccion from "@/components/globals/template/examenes/loadingSeccionExamen.vue";
import paginasAplicacionExamen from "../paginas/paginasAplicacionExamen.vue";
import cronometroSeccion from "./cronometroSeccionExamen.vue";
import { permisoMenuEscuela } from "../../../../../helpers/utilerias";

export default {
  components: {
    "loading-seccion": loadingSeccion,
    "paginas-examen": paginasAplicacionExamen,
    "cronometro-seccion": cronometroSeccion,
    "tiempo-agotado": () => import("./tiempoAgotadoSeccion.vue"),
  },

  data: () => ({
    loading: true,
    resultado: null,
    seccion: null,
    tiempoLimite: 0,
    cronometroActivo: false,
    mostrarAgotadoDialog: false,
    aplicacionCurso: null,
    busGuardado: new Vue(),
    escuelaTienePermiso: false,
    permisoEscritura: false,
  }),

  computed: {
    ...mapGetters(["sessionToken", "menu", "sesionCursoProfesor", "appName"]),

    esCampus() {
      return this.$route.params.tipoCurso === "campus";
    },

    tipoCurso() {
      return this.$route.params.tipoCurso;
    },

    idAplicacion() {
      return this.$route.params.idAplicacion;
    },

    idCurso() {
      return this.$route.params.idCurso;
    },

    idModulo() {
      return this.$route.params.idModulo;
    },

    idResultado() {
      return this.$route.params.idResultado;
    },

    tieneDescripcion() {
      return (
        this.seccion &&
        this.seccion.descripcion &&
        this.seccion.descripcion !== "<p></p>"
      );
    },

    prevSeccion() {
      if (this.seccion) {
        const { examen } = this.seccion;
        const indexAnterior = examen.secciones.indexOf(this.seccion._id) - 1;
        if (examen.secciones[indexAnterior])
          return examen.secciones[indexAnterior];
        else return null;
      }

      return null;
    },

    nextSeccion() {
      if (this.seccion) {
        const { examen } = this.seccion;
        const indexSiguiente = examen.secciones.indexOf(this.seccion._id) + 1;
        if (examen.secciones[indexSiguiente])
          return examen.secciones[indexSiguiente];
        else return null;
      }

      return null;
    },

    esAplicacionAlumno() {
      return (
        !!this.idResultado &&
        this.appName == "Campus" &&
        !this.sesionCursoProfesor &&
        !this.permisoEscritura
      );
    },

    esAplicacionFinalizada() {
      if (this.sesionCursoProfesor || this.appName != "Campus" || this.idCurso)
        return true;
      return (
        new Date(this.aplicacionCurso.fin).getTime() < new Date().getTime()
      );
    },

    soloLectura() {
      return (
        !this.esAplicacionAlumno ||
        (this.esAplicacionAlumno && this.esAplicacionFinalizada)
      );
    },
  },

  watch: {
    sessionToken(value) {
      if (value && this.idCurso) {
        if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
        else {
          this.escuelaTienePermiso = true;
          this.getSeccion();
        }
      }
      if (value && this.idResultado) {
        if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
        else {
          this.escuelaTienePermiso = true;
          this.getResultado();
        }
      }
    },

    "$route.params.idSeccion"() {
      if (this.idCurso) {
        if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
        else {
          this.escuelaTienePermiso = true;
          this.getSeccion();
        }
      }
      if (this.idResultado) {
        if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
        else {
          this.escuelaTienePermiso = true;
          this.getResultado();
        }
      }
    },
  },

  mounted() {
    if (this.sessionToken && this.idCurso) {
      if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
      else {
        this.escuelaTienePermiso = true;
        this.getSeccion();
      }
    }
    if (this.sessionToken && this.idResultado) {
      if (!permisoMenuEscuela("Cursos")) this.$router.push("/dashboard");
      else {
        this.escuelaTienePermiso = true;
        this.getResultado();
      }
    }
  },

  methods: {
    async getResultado() {
      this.loading = true;
      this.cronometroActivo = false;

      try {
        const params = {
          idResultado: this.idResultado,
          idSeccion: this.$route.params.idSeccion,
          idExamen: this.$route.params.idExamen,
        };
        const serverResponse = await cargarSeccionResultado(params);
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.permisoEscritura = serverResponse.permisoEscritura;
          this.seccion = serverResponse.seccion;
          this.resultado = serverResponse.resultado;
          this.aplicacionCurso = serverResponse.aplicacionCurso;
          this.tiempoLimite =
            Number(serverResponse.resultado.tiempoRestante) * 60;
          if (!this.esAplicacionFinalizada && !this.permisoEscritura)
            this.cronometroActivo = true;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    async getSeccion(params) {
      this.loading = true;

      try {
        const idSeccion = this.$route.params.idSeccion || params.idSeccion;
        const idExamen = this.$route.params.idExamen || params.idExamen;

        const serverResponse = await cargarSeccionHistoricoExamen({
          idExamen,
          idSeccion,
        });
        this.loading = false;

        if (!serverResponse.ok) {
          this.$systemErrorMessage(serverResponse.mensaje);
        } else {
          this.seccion = serverResponse.seccion;
          this.seccion.examen = serverResponse.examen;
        }
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    siguienteSeccion() {
      if (this.idCurso) {
        this.nextSeccion &&
          this.$router.push({
            name: this.$route.name,
            params: { idSeccion: this.nextSeccion },
          });
        !this.nextSeccion && this.regresarAction({});
      } else {
        this.siguienteSeccionResultado();
      }
    },

    async siguienteSeccionResultado() {
      this.loading = true;
      this.mostrarAgotadoDialog = false;

      this.busGuardado.$emit("guardarDatos");

      try {
        const sendActual =
          this.esAplicacionFinalizada ||
          this.sesionCursoProfesor ||
          this.permisoEscritura;
        const serverResponse = await avanzarSeccionResultado({
          idResultado: this.idResultado,
          idExamen: this.$route.params.idExamen,
          ...(sendActual && {
            seccionActual: this.$route.params.idSeccion,
          }),
        });
        this.loading = false;

        if (serverResponse.ok) {
          if (
            (serverResponse.resultado.terminado &&
              !this.esAplicacionFinalizada) ||
            ((this.esAplicacionFinalizada || this.sesionCursoProfesor) &&
              !serverResponse.siguiente)
          )
            this.regresarAction(serverResponse);
          else if (
            this.$route.params.idSeccion == serverResponse.resultado.seccion
          )
            this.regresarAction(serverResponse);
          else
            this.$router.push({
              name: this.$route.name,
              params: {
                idSeccion:
                  serverResponse.resultado.seccion || serverResponse.siguiente,
              },
            });
        } else this.$systemErrorMessage(serverResponse.mensaje);
      } catch (error) {
        console.error(error);
        this.loading = false;
        this.$appErrorMessage();
      }
    },

    regresarAction({ aplicacionCurso = {} }) {
      this.$router.push({
        name: "ExamenAplicacion",
        params: {
          idCurso: this.idCurso || aplicacionCurso.cursoCampus,
          idModulo: this.idModulo || aplicacionCurso.cursoModulo,
          tipoCurso: this.tipoCurso || "campus",
          idAplicacion: this.idAplicacion || aplicacionCurso._id,
        },
      });
    },

    minutoCumplido() {
      this.busGuardado.$emit("guardarDatos");
      this.restarTiempo({ minutos: 1 });
    },

    async restarTiempo(tiempo) {
      await restarTiempoResultado({ idResultado: this.idResultado, tiempo });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../classes/ck-content.css";
</style>
