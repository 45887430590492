import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VTabs,{attrs:{"vertical":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('div',_vm._l((_vm.paginas),function(pagina){return _c(VListItem,{key:pagina._id,class:{ 'pagina-activa': _vm.tab == pagina._id }},[_c(VListItemContent,[_c(VListItemTitle,[_c('a',{staticClass:"text-decoration-none",on:{"click":function($event){_vm.tab = pagina._id}}},[_vm._v(" "+_vm._s(pagina.nombre)+" ")])]),_c(VListItemSubtitle,[_c('span',[_vm._v(_vm._s(pagina.tipo))])])],1)],1)}),1),_c(VTabsItems,{staticClass:"pl-2",model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},_vm._l((_vm.paginas),function(pagina){return _c(VTabItem,{key:pagina._id,attrs:{"value":pagina._id}},[_c('pagina-examen',{attrs:{"pagina":pagina,"idExamen":_vm.idExamen,"soloLectura":_vm.soloLectura,"aplicacionFinalizada":_vm.aplicacionFinalizada,"busGuardado":_vm.busGuardado,"esAplicacionAlumno":_vm.esAplicacionAlumno},on:{"siguientePagina":_vm.siguientePagina}})],1)}),1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }