<template>
  <v-container>
    <v-skeleton-loader type="text"></v-skeleton-loader>
    <v-skeleton-loader type="paragraph" class="mt-3"></v-skeleton-loader>
    <v-skeleton-loader
      v-for="index in 3"
      :key="index"
      type="card"
      class="mt-3"
    ></v-skeleton-loader>
  </v-container>
</template>

<script>
export default {};
</script>
