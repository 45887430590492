<template>
  <v-card color="#eceff1" flat>
    <v-card-text>
      <div v-if="pagina.tipo === 'lectura'">
        <v-toolbar dense color="primary" dark flat shaped class="mb-3">
          <v-toolbar-title>Lectura</v-toolbar-title>
        </v-toolbar>
        <div
          v-html="pagina.lectura"
          class="ck-content mb-3"
          style="overflow: hidden"
        ></div>
      </div>

      <div>
        <v-toolbar dense color="secondary" dark flat shaped class="mb-3">
          <v-toolbar-title>Reactivos</v-toolbar-title>
        </v-toolbar>
        <loading-reactivos v-if="loading"></loading-reactivos>
        <span v-else-if="!reactivos.length"> Página sin reactivos. </span>
        <div v-else>
          <reactivo-aplicacion
            v-for="reactivo in reactivos"
            :key="reactivo._id"
            :reactivo="reactivo"
            :soloLectura="soloLectura"
            :aplicacionFinalizada="aplicacionFinalizada"
            :busGuardado="busGuardado"
            :esAplicacionAlumno="esAplicacionAlumno"
            class="mt-2"
          ></reactivo-aplicacion>
        </div>
      </div>

      <div class="mt-3">
        <v-btn color="primary" small @click="$emit('siguientePagina')">
          <span>Siguiente</span>
          <v-icon small class="ml-2">mdi-chevron-right</v-icon>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import loadingReactivos from "@/components/globals/template/examenes/reactivos/loadingReactivos.vue";
import reactivoAplicacionExamen from "../reactivo/reactivoAplicacionExamen.vue";

import { cargarPaginaHistoricoExamen } from "../../../../historicoExamen/historicoExamen.service";
import { cargarPaginaResultado } from "../examenAplicacionResultado.service";

export default {
  components: {
    "loading-reactivos": loadingReactivos,
    "reactivo-aplicacion": reactivoAplicacionExamen,
  },

  props: {
    pagina: { type: Object, required: true },
    idExamen: { type: String, required: false },
    soloLectura: { type: Boolean, default: true },
    aplicacionFinalizada: { type: Boolean, default: false },
    esAplicacionAlumno: { type: Boolean, default: false },
    busGuardado: { required: true },
  },

  data() {
    return {
      loading: true,
      paginaLocal: this.pagina,
      reactivos: [],
    };
  },

  computed: {
    ...mapGetters(["sessionToken"]),

    idResultado() {
      return this.$route.params.idResultado;
    },
  },

  watch: {
    sessionToken(value) {
      if (value) this.getPagina();
    },
  },

  mounted() {
    if (this.sessionToken) this.getPagina();
  },

  methods: {
    async getPagina() {
      this.loading = true;

      try {
        const idExamen = this.$route.params.idExamen || this.idExamen;
        const serverResponse = this.idResultado
          ? await cargarPaginaResultado({
              idResultado: this.idResultado,
              idExamen,
              idPagina: this.paginaLocal._id,
            })
          : await cargarPaginaHistoricoExamen({
              idExamen,
              idPagina: this.paginaLocal._id,
            });

        this.loading = false;
        this.$validateResponse(serverResponse);
        if (!serverResponse.ok)
          this.$systemErrorMessage(serverResponse.mensaje);
        else this.reactivos = serverResponse.pagina.reactivos;
      } catch (error) {
        this.loading = false;
        this.$appErrorMessage();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../classes/ck-content.css";
</style>
