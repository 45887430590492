<template>
  <div>
    <v-chip label class="cronometro" dark :color="colorCronometro">
      <span>{{ formatRestante() }}</span>
    </v-chip>
  </div>
</template>

<script>
export default {
  props: {
    activo: { type: Boolean, default: false },
    tiempoLimite: { type: [Number, String], required: true },
  },

  data: () => ({
    tiempoTranscurrido: 0,
    intervaloDeTiempo: null,
    proximoIntervalo: 60,
  }),

  computed: {
    segundosRestantes() {
      return this.tiempoLimite - this.tiempoTranscurrido;
    },

    colorCronometro() {
      if (!this.activo) return null;
      if (this.segundosRestantes < 120) return "red darken-2";
      else return null;
    },
  },

  watch: {
    activo(value) {
      if (value) this.comenzarCronometro();
    },

    tiempoTranscurrido(value) {
      if (value === this.tiempoLimite) this.$emit("tiempoAgotado");
      if (value - this.proximoIntervalo === 0) {
        this.$emit("minutoCumplido");
        this.proximoIntervalo = this.proximoIntervalo + 60;
      }
    },
  },

  mounted() {
    if (this.activo) this.comenzarCronometro();
  },

  methods: {
    comenzarCronometro() {
      this.tiempoTranscurrido = 0;
      if (this.intervaloDeTiempo) {
        clearInterval(this.intervaloDeTiempo);
        this.intervaloDeTiempo = null;
      }

      this.intervaloDeTiempo = setInterval(
        () => (this.tiempoTranscurrido += 1),
        1000
      );
    },

    formatRestante() {
      return new Date(this.segundosRestantes * 1000)
        .toISOString()
        .substr(14, 5);
    },
  },
};
</script>

<style lang="scss" scoped>
.cronometro {
  position: fixed;
  top: 1rem;
  right: 2rem;
  z-index: 5;
}
</style>
